import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import ReactQuill from "react-quill";
import { httpReauest } from "../../../utils/httpRequest";
import { Editor } from "@tinymce/tinymce-react";
import CustomModal from "../Modal";
import { CiHeart } from "react-icons/ci";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import UploadedModal from "../UploadedModal";
import { AuthContext } from "../../../context/AuthContext";

const CreateBlogForm = () => {
  const [img, setImg] = useState();
  const [show, setshow] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();
  const [categorys, setCategorys] = useState();
  const [drafts, setdrafts] = useState();
  const [file, setfile] = useState();
  const [text, settext] = useState();
  const [timing, settiming] = useState();
  const [blogCategory, setblogCategory] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });

    await httpReauest("GET", "/blog/draft", {}, {}).then(({ data }) => {
      setdrafts(data?.data);
    });

    await httpReauest("GET", "/blogcategory", {}, {}).then(({ data }) => {
      setblogCategory(data?.data);
    });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      url: Yup.string().required("الزامی است"),
      related: Yup.string().required("الزامی است"),
      category: Yup.string().required("الزامی است"),
    });
  };
  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      meta_keywords: "",
      meta_desc: "",
      content: "",
      desc: "",
      related: "",
      url: "",
      category: "",
      draft: false,
      date: "",
      _id: false,
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("author", user?.name);
      formdata.append("related", values.related);
      formdata.append("meta_keywords", values.meta_keywords);
      formdata.append("meta_desc", values.meta_desc);
      formdata.append("content", editorRef.current?.getContent());
      formdata.append("desc", values.desc);
      formdata.append("url", values.url);
      formdata.append("category", values.category);
      if (values.draft) {
        formdata.append("draft", values.draft);
      }

      if (values._id) {
        formdata.append("_id", values._id);
      }
      if (timing) {
        formdata.append("date", values.date);
      }
      if (img2) {
        formdata.append("cover", img2, img2?.name);
      }

      await fetch(BASE_URL + "/blog", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      })
        .then(async (dat) => {
          if (dat?.status == 201) {
            toast.success("بلاگ ساخته شد");
            setloading(false);
            formik.resetForm();
            setImg(null);
            setImg2(null);
            setPreviewImg(null);
            setPreviewImg2(null);
          } else {
            const message = await dat.json();
            setloading(false);
            toast.error(message.message);
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  function handleImg2(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg2(fils);
      setPreviwImage2(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function setPreviwImage2(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg2(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  const editorRef = useRef(null);

  async function handleUpload() {
    if (!file) {
      return toast.error("فایلی انتخاب نشده است");
    }

    const formdata = new FormData();
    formdata.append("file", file, file.name);

    const res = await fetch(BASE_URL + "/blog/upload", {
      method: "POST",
      body: formdata,
      headers: { "x-access-token": user.token },
    });

    const data = await res.json();

    settext(data.data);
    setfile(null);
  }

  async function handleDraft() {
    formik.setFieldValue("draft", true);
    formik.handleSubmit();
  }

  async function handleChangeSetBlog(e) {
    const newBrand = await drafts.find((asd) => asd?._id == e.target?.value);
    await httpReauest("GET", "/blog/" + newBrand.url, {}, {}).then(
      ({ data }) => {
        formik.setFieldValue("name", newBrand.name);
        formik.setFieldValue("_id", newBrand._id);
        formik.setFieldValue("desc", newBrand.desc);
        formik.setFieldValue("meta_desc", newBrand.meta_desc);
        formik.setFieldValue("meta_keywords", data.data?.meta_keywords);
        formik.setFieldValue("related", newBrand.related);
        formik.setFieldValue("draft", newBrand.draft);
        formik.setFieldValue("content", data.data?.content);
        formik.setFieldValue("url", data.data?.url);
        formik.setFieldValue("category", data.data?.category);
        setPreviewImg2(CDN_BASE_URL + newBrand?.cover);
      }
    );
  }

  return (
    <div>
      <CustomModal show={show} onHide={() => setshow(false)}>
        <img
          src={previewImg2}
          className="w-full max-h-[414px] object-cover my-10 rounded-[10px] "
          alt=""
        />
        <div className="py-1 text-center">
          <h1 className="title">{formik.values.name}</h1>
        </div>
        <div className="my-12">
          <h2 className="title">توضیحات</h2>
          <p className="desc">{formik.values.desc}</p>
        </div>

        <span className="col-span-full">
          <div className="">
            <div
              dangerouslySetInnerHTML={{
                __html: editorRef.current?.getContent(),
              }}
            />
            <div className="flex justify-between py-4 mt-3 border-b border-[#8E9192]">
              <span className="flex items-start gap-3">
                <span className="flex gap-2">
                  <img src="/Star.png" className="w-[22px]" alt="start" />
                  <img src="/Star.png" className="w-[22px]" alt="start" />
                  <img src="/Star.png" className="w-[22px]" alt="start" />
                  <img src="/Star.png" className="w-[22px]" alt="start" />
                  <img src="/Star.png" className="w-[22px]" alt="start" />
                </span>
                <p>0/5</p>
              </span>
              <span className="flex gap-2">
                <img src="/share.png" className="w-[22px]" alt="start" />

                <CiHeart size={24} />
              </span>
            </div>
          </div>
        </span>
      </CustomModal>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد بلاگ</h2>

      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="brand"
            name="brand"
            onChange={handleChangeSetBlog}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            {drafts?.map((e) => {
              if (!e.end) {
                return <option value={e._id}>{e.name}</option>;
              }
            })}
          </select>
          <p className="on-border font-bold">انتخاب از بین draft ها</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : دانتستی ها درباره فوتون 450"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">نام بلاگ*</p>
        </span>
        <span className="relative">
          <input
            id="url"
            name="url"
            value={formik.values.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.url && formik.touched.url && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.url}
            </small>
          )}
          <p className="on-border font-bold">url*</p>
        </span>
        <span className="relative">
          <select
            id="related"
            name="related"
            value={formik.values.related}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            {categorys?.map((e) => (
              <option value={e._id}>{e.name}</option>
            ))}
          </select>
          {formik.errors.related && formik.touched.related && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.related}
            </small>
          )}
          <p className="on-border font-bold">مرتبط با دسته بندی*</p>
        </span>
        <span className="relative">
          <select
            id="category"
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            {blogCategory?.map((e) => (
              <option value={e._id}>{e.name}</option>
            ))}
          </select>
          {formik.errors.category && formik.touched.category && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.category}
            </small>
          )}
          <p className="on-border font-bold">دسته بندی بلاگ</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : کامیون , کشنده , فوتون 450"
            id="meta_keywords"
            name="meta_keywords"
            value={formik.values.meta_keywords}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          <p className="on-border font-bold">کلمات کلیدی*</p>
          {formik.errors.meta_keywords && formik.touched.meta_keywords && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.meta_keywords}
            </small>
          )}
        </span>

        <span className="relative col-span-full">
          <textarea
            placeholder="مثلا : دانتستی ها درباره فوتون 450"
            id="meta_desc"
            name="meta_desc"
            value={formik.values.meta_desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[88px]"
          />
          {formik.errors.meta_desc && formik.touched.meta_desc && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.meta_desc}
            </small>
          )}
          <p className="on-border font-bold">توضیح متا*</p>
        </span>
        <span className="relative col-span-full">
          <textarea
            placeholder="مثلا : دانتستی ها درباره فوتون 450"
            id="desc"
            name="desc"
            value={formik.values.desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[88px]"
          />
          {formik.errors.desc && formik.touched.desc && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.desc}
            </small>
          )}
          <p className="on-border font-bold">مقدمه</p>
        </span>
        <input
          onChange={(e) => {
            handleImg2(e);
          }}
          type="file"
          className="hidden"
          id="img2"
          accept="image/png, image/jpg, image/jpeg"
        />
        <label
          htmlFor="img2"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444] col-span-full"
        >
          {previewImg2 ? (
            <img
              src={previewImg2}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">کاور</p>
        </label>
        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />
        <span className="col-span-full">
          <h5 className="text-[24px] font-bold">آپلود:</h5>
          <input
            onChange={(e) => {
              setfile(e.target.files[0]);
            }}
            type="file"
            className="my-2"
            id="fileUpload"
            accept="image/*, video/*"
          />
          {text && <div className="mb-2"> آدرس : {CDN_BASE_URL + text}</div>}

          <button type="button" onClick={handleUpload} className="btn-brand">
            آپلود
          </button>
          <UploadedModal />
        </span>
        <span className="col-span-full">
          <h1 className="title text-right">محتوا:</h1>
          <p className="mb-3">
            برای آپلود تصویر یا ویدیو از کامپیوتر روی بخش آپلود بالای سر کلیک
            کنید و فایل را اپلود کنید بعد از ان به شما لینک فایل را میدهد تا
            بتوانید در محتوا استفاده کنید
          </p>
          <Editor
            apiKey="n8nkotsvldkyous3nvn6bd1ez8u5ovbll2ohi5jdt0fuo8gi"
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={formik.values.content}
            init={{
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount  linkchecker code",
              toolbar:
                "undo redo | blocks fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              mergetags_list: [
                { value: "First.Name", title: "First Name" },
                { value: "Email", title: "Email" },
              ],
              ai_request: (request, respondWith) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
            }}
          />
        </span>
        {timing && (
          <span>
            <label>انتخاب زمان برای انتشار : </label>
            <DatePicker
              format="MM/DD/YYYY HH:mm:ss"
              onChange={(e) => {
                formik.setFieldValue("date", new Date(e));
              }}
              calendar={persian}
              locale={persian_fa}
              plugins={[<TimePicker position="bottom" />]}
              calendarPosition="bottom-right"
              className="form-brand w-full"
              style={{ height: "45px" }}
            />
          </span>
        )}
        <span className="col-span-full">
          {loading ? (
            <Loading />
          ) : (
            <span className="flex gap-3 flex-wrap">
              <button className="btn-brand">ارسال</button>
              <button onClick={handleDraft} type="button" className="btn-brand">
                Draft
              </button>
              <button
                type="button"
                onClick={() => setshow(true)}
                className="btn-brand"
              >
                پیش نمایش
              </button>
              <button
                type="button"
                onClick={() => settiming(!timing)}
                className="btn-brand"
              >
                زمان بندی
              </button>
            </span>
          )}
        </span>
      </form>
    </div>
  );
};

export default CreateBlogForm;
