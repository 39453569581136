import React from "react";
import RequestBuy from "../components/common/requestForm/RequestBuy";
import RequestGetList from "../components/common/requestForm/RequestGetList";
import {
  FaMoneyBillAlt,
  FaRegClock,
  FaRegEdit,
  FaRegFileAlt,
  FaTimes,
  FaUser,
} from "react-icons/fa";
import { useState } from "react";
import Loading from "../components/common/Loading";
import EditProductForm from "../components/common/forms/EditProductForm";
import EditTimerProduct from "../components/common/forms/EditTimerProduct";
import CreateProductForm from "../components/common/forms/CreateProductForm copy";
import TableBuySell from "../components/common/TableBuySell";
import TableListPrice from "../components/common/TableListPrice";
import EditMoreProductForm from "../components/common/forms/EditMoreProductForm";
import CreateEventForm from "../components/common/forms/CreateEventForm";
import EditEventForm from "../components/common/forms/EditEventForm";
import TableSignUser from "../components/common/TableSignUser";

const EventManagePage = () => {
  const [editing, setEditing] = useState();
  const [editingMore, setEditingMore] = useState();
  const [creating, setCreating] = useState();
  const [timing, settiming] = useState();
  const [list, setlist] = useState();
  const [loading, setLoading] = useState();

  async function handleCreateShow(params) {
    setCreating(true);
  }

  async function handleEditShow(params) {
    setEditing(true);
  }

  async function handleEditMoreShow(params) {
    setEditingMore(true);
  }

  async function handleTimeShow(params) {
    settiming(true);
  }

  async function handleListShow(params) {
    setlist(true);
  }

  function handleBack() {
    setCreating(false);
    setEditing(false);
    setEditingMore(false);
    settiming(false);
    setlist(false);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full">
      <h1 className="title mb-10">مدیریت رویداد ها</h1>
      {!editing && !creating && !timing && !list && !editingMore && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <span className="col-span-full">
            <p className="text-center font-bold text-[28px]">
              چه کاری میخوای انجام بدی؟
            </p>
          </span>
          <span
            onClick={handleCreateShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              ایجاد رویداد جدید
            </p>
            <FaRegFileAlt
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleEditShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              تغییر رویداد های موجود
            </p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleTimeShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              کاربران ثبت نام شده برای روادید
            </p>
            <FaUser
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
        </div>
      )}

      {(editing || creating || timing || list || editingMore) && (
        <>
          <button onClick={handleBack} className="btn-brand mb-10">
            بازگشت
          </button>
          <br />
        </>
      )}

      {creating && <CreateEventForm />}
      {editing && <EditEventForm />}
      {timing && <TableSignUser />}
    </div>
  );
};

export default EventManagePage;
