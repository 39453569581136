import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { httpReauest } from "../../utils/httpRequest";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import Loading from "./Loading";

const TableSignUser = () => {
  const { user, setUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [selected, setselected] = useState();
  const [loading, setloading] = useState();

  async function getData(params) {
    setloading(true);
    await httpReauest("GET", "/event", {}, {}).then((res) =>
      setData(res.data.data)
    );
    setloading(false);
  }
  useEffect(() => {
    getData();
  }, []);

  async function handleChange(_id) {
    await httpReauest("GET", "/event/user/" + _id, {}, {}).then((res) => {
      setselected(res.data.data);
    });
  }

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <span className="relative">
        <select
          id="vehicle"
          name="vehicle"
          onChange={(e) => handleChange(e.target.value)}
          className={` w-full form-brand h-[48px]`}
        >
          <option>{loading && <Loading />}</option>
          {data?.map((e) => {
            return <option value={e._id}>{e.name}</option>;
          })}
        </select>
        <p className="on-border font-bold">انتخاب رویداد*</p>
      </span>

      {selected && (
        <>
          <h2 className="title my-3">کاربر ها ({selected?.length})</h2>

          <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
            <thead class="text-xs text-white uppercase bg-orange-600 dark:text-white">
              <tr>
                <th scope="col" class="px-6 py-3">
                  نام
                </th>
                <th scope="col" class="px-6 py-3">
                  تلفن همراه
                </th>
                <th scope="col" class="px-6 py-3">
                  کد ملی
                </th>
                <th scope="col" class="px-6 py-3">
                  تاریخ ثبت نام
                </th>

                <th scope="col" class="px-6 py-3">
                  تصویر پرداخت
                </th>
              </tr>
            </thead>
            <tbody>
              {selected?.map((req) => {
                return (
                  <tr class="bg-orange-500 border-blue-40">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100"
                    >
                      {req?.name}
                    </th>
                    <td class="px-6 py-4">{req?.phone}</td>
                    <td class="px-6 py-4">{req?.nationalCode}</td>
                    <td class="px-6 py-4">
                      {req?.createdAt &&
                        new Intl.DateTimeFormat("fa-IR").format(
                          new Date(req?.createdAt)
                        )}
                    </td>
                    <td class="px-6 py-4">
                      <Link
                        to={CDN_BASE_URL + req?.payment}
                        target="_blank"
                        className="btn-brand w-fit"
                      >
                        مشاهده
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default TableSignUser;
