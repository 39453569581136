import "./App.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Layout from "./components/layout";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "swiper/css";
import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import { httpReauest } from "./utils/httpRequest";
import ProductManagePage from "./pages/ProductManagePage";
import BrandManagePage from "./pages/BrandManagePage";
import BlogManagePage from "./pages/BlogManagePage";
import "react-quill/dist/quill.snow.css";
import AdminManagePage from "./pages/AdminManagePage";
import Chat from "./pages/Chat";
import { initiateSocketConnection } from "./helpers/socketHelper";
import StoryManagePage from "./pages/StoryManagePage";
import CategoryManagePage from "./pages/CategoryManagePage";
import NotificationManagePage from "./pages/NotificationManagePage";
import UrlManagePage from "./pages/UrlManagePage";
import { AuthContext } from "./context/AuthContext";
import LogPage from "./pages/LogPage";
import ExpertManagePage from "./pages/ExpertManagePage";
import EventManagePage from "./pages/EventManagePage";

initiateSocketConnection();

function App() {
  useEffect(() => {
    getData();
  }, []);

  const [admin, setAdmin] = useState();
  const [user, setUser] = useState();

  async function getData() {
    if (window.location.pathname == "/chat") {
    } else {
      if (user) {
        await httpReauest(
          "POST",
          "/admin/islogined",
          {},
          { "x-auth-token": user?.token }
        ).catch(() => {
          window.location.href = window.origin + "/";
          localStorage.removeItem("user");
        });
      } else {
        if (window.location.href != window.origin + "/") {
          window.location.href = window.origin + "/";
        }
      }
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <LoginPage />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <>
          <Layout admin={admin}>
            <HomePage />
          </Layout>
        </>
      ),
    },
    {
      path: "/product",
      element: (
        <>
          <Layout admin={admin}>
            <ProductManagePage />
          </Layout>
        </>
      ),
    },
    {
      path: "/event",
      element: (
        <>
          <Layout admin={admin}>
            <EventManagePage />
          </Layout>
        </>
      ),
    },
  ]);

  return (
    <AuthContext.Provider value={{ user, setUser, setAdmin }}>
      <RouterProvider router={router} />
    </AuthContext.Provider>
  );
}

export default App;
