import React, { useContext } from "react";
import { httpReauest } from "../../utils/httpRequest";
import { FaCheck, FaTimes } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { CDN_BASE_URL } from "../../config";

const TableGetList = ({ data, setData }) => {
  const { user, setUser } = useContext(AuthContext);

  async function acceptOrDecline(id, status) {
    await httpReauest(
      "POST",
      "/user/payment/status",
      { id, status },
      { "x-access-token": user.token }
    ).then(() => {
      const updatedData = data.map((item) => {
        // اگر شناسه‌ها برابر هستند، تغییرات را اعمال کن
        if (status == false) {
          return;
        }
        if (status === true && id == item._id) {
          return { ...item, premium: true }; // تغییر newMessage به false
        }
        return item; // در غیر این صورت، عنصر را بدون تغییر برگردان
      });
      setData(updatedData);
    });
  }

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
        <thead class="text-xs text-white uppercase bg-orange-600 dark:text-white">
          <tr>
            <th scope="col" class="px-6 py-3">
              نام و نام خانوادگی
            </th>
            <th scope="col" class="px-6 py-3">
              شماره تماس
            </th>
            <th scope="col" class="px-6 py-3">
              رسید
            </th>
            <th scope="col" class="px-6 py-3">
              اعلام وضعیت
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((req) => {
            if (req?.in_proggress) {
              return (
                <tr class="bg-orange-500 border-blue-40">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100"
                  >
                    {req?.f_name} {req?.l_name}
                  </th>
                  <td class="px-6 py-4">{req?.phone}</td>
                  <td class="px-6 py-4">
                    <a href={CDN_BASE_URL + req?.payment} target="_blank">
                      <img
                        src={CDN_BASE_URL + req?.payment}
                        className="w-[120px]"
                      />
                    </a>
                  </td>
                  <td class="px-6 py-4">
                    {req.premium ? (
                      <FaCheck className="text-green-500" />
                    ) : (
                      <div className="flex gap-2">
                        <button
                          onClick={() => acceptOrDecline(req._id, true)}
                          className="btn-brand"
                        >
                          قبول
                          <FaCheck className="text-green-500" />
                        </button>
                        <button
                          onClick={() => acceptOrDecline(req._id, false)}
                          className="btn-brand"
                        >
                          رد کردن
                          <FaTimes className="text-red-500" />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableGetList;
