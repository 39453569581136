import React, { useContext, useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import Loading from "../Loading";
import { AuthContext } from "../../../context/AuthContext";

const EditDealerData = ({ data }) => {
  const [loading, setloading] = useState();
  const [file, setfile] = useState();

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      name: data?.name ? data?.name : "",
      date: data?.date ? data?.date : "",
    },
    onSubmit: async (values) => {
      setloading(true);
      const formdata = new FormData();

      formdata.append("name", values.name);
      formdata.append("date", values.date);
      if (file) {
        formdata.append("file", file, file?.name);
      }

      await fetch(BASE_URL + "/admin/gift", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("هدیه تغییر یافت");
          setloading(false);
          formik.resetForm();
        }
      });
    },
  });

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر دسته بندی</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          <p className="on-border font-bold">توضیح*</p>
        </span>
        <span className="relative">
          <input
            id="date"
            name="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            className="w-full form-brand h-[48px]"
          />
          <p className="on-border font-bold">مهلت دریافت هدیه (ساعت)*</p>
        </span>
        <span>
          <p className="mb-3 font-bold">فایل هدیه*</p>
          <input
            onChange={(e) => {
              setfile(e.target.files[0]);
            }}
            type="file"
          />
        </span>
        <span className="col-span-full">
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        </span>
      </form>
    </div>
  );
};

export default EditDealerData;
