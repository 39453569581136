import React, { useContext, useState } from "react";
import Filter from "../components/common/Filter";
import RequestBuy from "../components/common/requestForm/RequestBuy";
import DiscountedProduct from "../components/common/DiscountedProduct";
import Products from "../components/common/Products";
import { FaDollarSign, FaEdit, FaUser } from "react-icons/fa";
import Loading from "../components/common/Loading";
import { httpReauest } from "../utils/httpRequest";
import TableBuySell from "../components/common/TableBuySell";
import TableGetList from "../components/common/TableGetList";
import EditDealerData from "../components/common/forms/EditDealerData";
import { AuthContext } from "../context/AuthContext";

const HomePage = () => {
  const [list1, setList1] = useState();
  const [list2, setList2] = useState();
  const [list3, setList3] = useState();
  const [list4, setList4] = useState();
  const [list5, setList5] = useState();
  const [loading, setloading] = useState();
  const { user, setUser, setAdmin } = useContext(AuthContext);

  async function handleList1(params) {
    setloading(true);

    await httpReauest(
      "GET",
      "/user",
      {},
      { "x-access-token": user.token }
    ).then((data) => {
      setList1(data.data.data);
      setloading(false);
    });
  }

  async function handleList2(params) {
    setloading(true);

    await httpReauest(
      "GET",
      "/user",
      {},
      { "x-access-token": user.token }
    ).then((data) => {
      setList2(data.data.data);
      setloading(false);
    });
  }

  async function handleList4(params) {
    setloading(true);

    await httpReauest("GET", "/request/talk", {}, {}).then((data) => {
      setList4(data.data.data);
      setloading(false);
    });
  }

  async function handleList5(params) {
    setloading(true);

    await httpReauest("GET", "/admin/gift", {}, {}).then((res) => {
      setList5(res.data.data);
      setloading(false);
    });
  }
  function handleBack(params) {
    setList1(false);
    setList2(false);
    setList3(false);
    setList4(false);
    setList5(false);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full">
      <h1 className="title mb-10">درخواست ها</h1>

      {!list1 && !list2 && !list3 && !list4 && !list5 && (
        <div className="w-full grid grid-cols-1 gap-7">
          <span
            onClick={handleList1}
            className="border-brand p-5 flex gap-3 justify-between items-center hover:bg-black hover-text-secendory duration-300 cursor-pointer"
          >
            <span className="font-bold text-[18px] lg:text-[26px]">
              کاربران سایت
            </span>
            <span className="bg-red-400 p-2 rounded-[10px]">
              <FaUser color="#000" size={45} />
            </span>
          </span>
          <span
            onClick={handleList2}
            className="border-brand p-5 flex gap-3 justify-between items-center hover:bg-black hover-text-secendory duration-300 cursor-pointer"
          >
            <span className="font-bold text-[18px] lg:text-[26px]">
              مدیریت رسید های اشتراک
            </span>
            <span className="bg-red-400 p-2 rounded-[10px]">
              <FaDollarSign color="#000" size={45} />
            </span>
          </span>
          <span
            onClick={handleList5}
            className="border-brand p-5 flex gap-3 justify-between items-center hover:bg-black hover-text-secendory duration-300 cursor-pointer"
          >
            <span className="font-bold text-[18px] lg:text-[26px]">
              هدیه سایت
            </span>
            <span className="bg-orange-400 p-2 rounded-[10px]">
              <FaEdit color="#000" size={45} />
            </span>
          </span>
        </div>
      )}

      {(list1 || list2 || list3 || list4 || list5) && (
        <>
          <button onClick={handleBack} className="btn-brand mb-10">
            بازگشت
          </button>
          <br />
        </>
      )}

      {list1 && <TableBuySell data={list1} setData={setList1} />}
      {list2 && <TableGetList data={list2} setData={setList2} />}
      {list3 && <TableGetList data={list3} setData={setList3} />}
      {list4 && <TableBuySell data={list4} setData={setList4} />}
      {list5 && <EditDealerData data={list5} />}
    </div>
  );
};

export default HomePage;
