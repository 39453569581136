import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { httpReauest } from "../../utils/httpRequest";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";

const TableBuySell = ({ data, setData }) => {
  const { user, setUser } = useContext(AuthContext);

  async function postRead(id) {
    await httpReauest(
      "POST",
      "/request/read",
      { id },
      { "x-access-token": user.token }
    ).then(() => {
      const updatedData = data.map((item) => {
        // اگر شناسه‌ها برابر هستند، تغییرات را اعمال کن
        if (id == item._id) {
          return { ...item, read: true }; // تغییر newMessage به false
        }
        return item; // در غیر این صورت، عنصر را بدون تغییر برگردان
      });
      setData(updatedData);
    });
  }

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <h2 className="title mb-3">کاربر ها ({data?.length})</h2>
      <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
        <thead class="text-xs text-white uppercase bg-orange-600 dark:text-white">
          <tr>
            <th scope="col" class="px-6 py-3">
              نام
            </th>
            <th scope="col" class="px-6 py-3">
              تلفن همراه
            </th>
            <th scope="col" class="px-6 py-3">
              تاریخ ثبت نام
            </th>
            <th scope="col" class="px-6 py-3">
              اشتراک
            </th>
            <th scope="col" class="px-6 py-3">
              تصویر اشتراک
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((req) => {
            return (
              <tr class="bg-orange-500 border-blue-40">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100"
                >
                  {req?.f_name} {req?.l_name}
                </th>
                <td class="px-6 py-4">{req?.phone}</td>
                <td class="px-6 py-4">
                  {req?.createdAt &&
                    new Intl.DateTimeFormat("fa-IR").format(
                      new Date(req?.createdAt)
                    )}
                </td>
                <td class="px-6 py-4">{req?.premium ? "دارد" : "ندارد"}</td>
                <td class="px-6 py-4">
                  {req?.premium ? (
                    <Link
                      to={CDN_BASE_URL + req?.payment}
                      target="_blank"
                      className="btn-brand w-fit"
                    >
                      مشاهده
                    </Link>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableBuySell;
